import React from "react";

const Footer = () => {
  return (
    <footer className="app-footer">
      <span className="d-inline-block">Ymo App</span>
    </footer>
  );
};
export default Footer;
