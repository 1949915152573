import React from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
// import ApolloClient from "apollo-boost";
import { ApolloClient } from "apollo-client";
import { ApolloProvider } from "@apollo/react-hooks";
import configureStore, { history } from "./store";
import App from "./containers/App";

import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { split } from "apollo-link";
import { InMemoryCache } from "apollo-cache-inmemory";

export const store = configureStore();

var dotenv = require("dotenv").config();

const { env } = process.env;

let apiUri = "https://api-gql.ymo.app/graphql";
//apiUri = "http://localhost:4010/graphql";
//apiUri = "https://pp-api-gql.ymo.app/graphql";

const httpLink = new createHttpLink({
  uri: apiUri,
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  let token = localStorage.getItem("token");
  if (token) {
    token = token.replace('"', "").replace('"', "");
  }
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
  mutate: {
    errorPolicy: "all",
  },
};
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions,
});

const MainApp = () => (
  <ApolloProvider client={client}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </ConnectedRouter>
    </Provider>
  </ApolloProvider>
);

export default MainApp;
