import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET
} from "../constants/ActionTypes";
import axios from "util/Api";
import { decodeToken } from "../util/utilLogin";
export const setInitUrl = url => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignUp = ({ name, email, password }) => {
  console.log(name, email, password);
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios
      .post("auth/register", {
        email: email,
        password: password,
        name: name
      })
      .then(({ data }) => {
        console.log("data:", data);
        if (data.result) {
          localStorage.setItem(
            "token",
            JSON.stringify(data.token.access_token)
          );
          axios.defaults.headers.common["access-token"] =
            "Bearer " + data.token.access_token;
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_TOKEN_SET, payload: data.token.access_token });
          dispatch({ type: USER_DATA, payload: data.user });
        } else {
          console.log("payload: data.error", data.error);
          dispatch({ type: FETCH_ERROR, payload: "Network Error" });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const userSignIn = ({ data }) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    if (data.loginAdmin.status) {
      localStorage.setItem("token", JSON.stringify(data.loginAdmin.token));
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: USER_TOKEN_SET, payload: data.loginAdmin.token });
    } else {
      dispatch({ type: FETCH_ERROR, payload: data.loginAdmin.message });
    }
  };
};
export const getUser = () => {
  return async dispatch => {
    const data = await decodeToken();
    dispatch({ type: FETCH_START });
    try {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: USER_DATA, payload: data });
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      console.log("Error****:", error.message);
    }
  };
};

export const userSignOut = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    dispatch({ type: FETCH_SUCCESS });
    localStorage.removeItem("token");
    dispatch({ type: SIGNOUT_USER_SUCCESS });
  };
};
