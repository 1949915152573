import jwtDecode from "jwt-decode";
const token = "cu2JXNLdYi014d9ZWkHnvKsb3g1I8H9E";

export const decodeToken = async () => {
  const newToken = await JSON.parse(localStorage.getItem("token"));
  console.log(newToken);
  if (newToken !== null) {
    return await jwtDecode(newToken, token);
  }
  return null;
};

export const getToken = async () => {
  let token = localStorage.getItem("token");
  if (token) {
    token = token.replace('"', "").replace('"', "");
  }
  console.log("TOKEN", token);
  return token;
};
