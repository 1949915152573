import gql from "graphql-tag";

export const gqlGetUserById = gql`
  query getUserById($id: String!) {
    getUserById(id: $id) {
      id
      is_subscribed
      firstname
      phone
      lastname
      email

      agency_id {
        address
        zipcode
      }
    }
  }
`;

export const gqlGetAdminStats = gql`
  query getAdminStats($date_start: String, $date_end: String) {
    getAdminStats(date_start: $date_start, date_end: $date_end) {
      countAllAi
      countPremiumAi
      countAllMandant
      countAiConnected
    }
  }
`;

export const gqlGetActivities = gql`
  query getActivities($type: String) {
    getActivities(type: $type) {
      user_id {
        firstname
        lastname
        phone
        user_type
      }
      type
      activity
      created_at
    }
  }
`;

export const gqlCountAiListing = gql`
  query countAiListing {
    countAiListing {
      todo
      new
      archived
      all
      active
      online
      observation
      subscribed
    }
  }
`;

export const gqlGetAdminCustomers = gql`
  query getAdminCustomers {
    getAdminCustomers {
      id
      active
      lastname
      avatar
      nb_mandate
      nb_sold
      nb_free_mandate
      last_connexion
      firstname
      user_type
      is_cda
      is_subscribed
      email
      phone
      agent_type
      created_at
      updated_at
      version_app
      type_freemium
      date_freemium
      user_poste
      agency_id {
        name
        address
        zipcode
        latitude
        longitude
      }
    }
  }
`;

export const gqlGetAdminAiListing = gql`
  query getAdminAiListing($listing: String) {
    getAdminAiListing(listing: $listing) {
      id
      active
      lastname
      avatar
      nb_mandate
      nb_sold
      nb_free_mandate
      last_connexion
      firstname
      user_type
      is_cda
      is_subscribed
      email
      phone
      agent_type
      created_at
      updated_at
      version_app
      crm_listing
      crm_status
      crm_date_action
      crm_source
      type_freemium
      date_freemium
      user_poste
      agency_id {
        name
        address
        zipcode
        latitude
        longitude
      }
    }
  }
`;

export const gqlGetUserByPhone = gql`
  query getUserByPhone($phone: String!) {
    getUserByPhone(phone: $phone) {
      user {
        activity {
          type
          activity
          created_at
        }
        id
        nb_sold
        active
        lastname
        avatar
        nb_mandate
        nb_free_mandate
        firstname
        user_type
        is_subscribed
        email
        phone
        agent_type
        created_at
        updated_at
        last_connexion
        version_app
        crm_listing
        crm_status
        crm_date_action
        crm_source
        crm_name
        type_freemium
        date_freemium
        user_poste
        agency_id {
          name
          address
          zipcode
        }

        crm_message {
          message
          created_at
          admin_user_id {
            last_name
            first_name
          }
        }
      }
      prospection {
        notes
        qualification
        status
      }
      projects {
        id
        reminder_ai
        is_mandate_signed
        last_action {
          type_id_action
          type_action
          updated_at
          reminder_ai
          date_action
        }
        updated_at
        created_at
        private
        liste_document
        liste_document_mandant
        private
        mandant_id {
          id
          phone
          gender
          firstname
          lastname
          is_online
          email
          last_connexion
        }
        actions {
          type_action
          type_id_action
          updated_at
          created_at
          type_action_title
        }
        mandate_id {
          id
          price
          commission_vente
          type
          address
          nb_piece
          superficie
          access
          latitude
          longitude
          zipcode
          city
          pictures {
            picture
            position
          }
        }
      }
    }
  }
`;

export const gqlLoginAdmin = gql`
  mutation loginAdmin($email: String!, $password: String!) {
    loginAdmin(email: $email, password: $password) {
      token
      status
      message
    }
  }
`;

export const gqlBlockUser = gql`
  mutation blockUser($id: String!) {
    blockUser(id: $id) {
      statut
    }
  }
`;

export const gqlUpdateUserAdmin = gql`
  mutation updateUserAdmin(
    $id: String!
    $nb_free_mandate: Int
    $crm_listing: String
    $crm_status: String
    $crm_date_action: Date
    $crm_source: String
  ) {
    updateUserAdmin(
      id: $id
      nb_free_mandate: $nb_free_mandate
      crm_listing: $crm_listing
      crm_status: $crm_status
      crm_date_action: $crm_date_action
      crm_source: $crm_source
    ) {
      statut
    }
  }
`;

export const gqlUpdateAiProspection = gql`
  mutation updateAiProspection(
    $ai_id: String!
    $qualification: Int
    $notes: String
    $status: String
    $date_appel: Date
    $date_rappel: Date
  ) {
    updateAiProspection(
      ai_id: $ai_id
      qualification: $qualification
      notes: $notes
      status: $status
      date_appel: $date_appel
      date_rappel: $date_rappel
    ) {
      statut
    }
  }
`;

export const gqlAddCrmMessage = gql`
  mutation addCrmMessage($message: String!, $user_id: String!) {
    addCrmMessage(message: $message, user_id: $user_id) {
      statut
    }
  }
`;

export const gqlSubActivite = gql`
  subscription getUpdatedActivity($type: String) {
    getUpdatedActivity(type: $type) {
      user_id {
        firstname
        lastname
        phone
        user_type
      }
      type
      activity
      created_at
    }
  }
`;
